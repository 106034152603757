/* AddUser.css */

.add-user-container {
  max-width: 600px;
  margin: 50px auto;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: auto; /* Lägg till denna rad för att tillåta skrollning */
}

.add-user-container h1,
.add-user-container h2 {
  text-align: center;
  margin-bottom: 20px;
}

.add-user-container form {
  display: flex;
  flex-direction: column;
}

.add-user-container label {
  margin-bottom: 8px;
  font-weight: bold;
}

.add-user-container input,
.add-user-container select {
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.add-user-container button {
  padding: 10px;
  background-color: #333;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
}

.add-user-container button:hover {
  background-color: #555;
}

.add-user-container p {
  text-align: center;
  color: green;
}

.user-list {
  list-style-type: none;
  padding: 0;
}

.user-list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #fff;
  border: 1px solid #ddd;
  margin-bottom: 10px;
  border-radius: 4px;
}

.edit-button,
.delete-button {
  padding: 5px 10px;
  margin-left: 10px;
  cursor: pointer;
}

.edit-button {
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
}

.edit-button:hover {
  background-color: #45a049;
}

.delete-button {
  background-color: #f44336;
  color: white;
  border: none;
  border-radius: 4px;
}

.delete-button:hover {
  background-color: #da190b;
}