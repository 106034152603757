/* Layout.css */

.layout {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
}

.header {
  display: flex;
  align-items: center;
  justify-content: center; /* Centrera rubriken */
  background-color: #444;
  color: white;
  height: 80px;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 1000;
  padding: 0 20px; /* Justera för att få in rubriken lite */
  box-sizing: border-box;
}

.header h1 {
  font-size: 1.8em;
  color: white;
  margin: 0;
  flex: 1;
  text-align: center;
}

.menu-icon {
  font-size: 24px;
  cursor: pointer;
}

.sidebar {
  background-color: #444;
  color: white;
  width: 250px;
  height: calc(100% - 80px); /* Justera för rubrikens höjd */
  position: fixed;
  top: 80px; /* Placera under headern */
  left: -250px;
  overflow-x: hidden;
  transition: 0.3s;
  z-index: 999;
  padding-top: 20px;
}

.sidebar.open {
  left: 0;
}

.sidebar nav ul {
  list-style-type: none;
  padding: 0;
}

.sidebar nav ul li {
  padding: 8px 16px;
  text-align: left;
}

.sidebar nav ul li a {
  color: white;
  text-decoration: none;
  display: block;
}

.sidebar nav ul li a:hover {
  background-color: #575757;
}

.sidebar nav ul li button.logout-button {
  background-color: #f44336;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  cursor: pointer;
  text-align: left;
  width: 100%;
}

.sidebar nav ul li button.logout-button:hover {
  background-color: #da190b;
}

.content {
  margin-top: 60px; /* Justera för headern */
  margin-left: 0;
  padding: 20px;
  flex-grow: 1;
  transition: margin-left 0.3s;
  overflow: auto;
}

.sidebar.open ~ .content {
  margin-left: 250px;
}