.user-statistics-container {
    width: 90%;
    max-width: 1200px;
    margin: 20px auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  h1 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .filter-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .filter-group {
    display: flex;
    flex-direction: column;
  }
  
  .filter-buttons {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .filter-button,
  .reset-button {
    padding: 10px 20px;
    background-color: #17a2b8;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .reset-button {
    background-color: #6c757d;
  }
  
  .filter-button:hover,
  .reset-button:hover {
    opacity: 0.8;
  }
  
  .table-container {
    margin-top: 20px;
  }
  
  .user-statistics-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .user-statistics-table th, .user-statistics-table td {
    padding: 12px;
    border: 1px solid #ddd;
    text-align: center;
  }
  
  .user-statistics-table th {
    background-color: #f2f2f2;
    font-weight: bold;
  }

  .filter-buttons {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .filter-button,
  .reset-button,
  .delete-button {
    padding: 10px 20px;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .filter-button {
    background-color: #17a2b8;
  }
  
  .reset-button {
    background-color: #6c757d;
  }
  
  .delete-button {
    background-color: #dc3545;
  }
  
  .filter-button:hover,
  .reset-button:hover,
  .delete-button:hover {
    opacity: 0.8;
  }