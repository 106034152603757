.statistics-container {
  width: 90%;
  margin: 0 auto;
  padding: 20px;
}

/* Tabelllayout för filtersektionen */
.filter-table {
  width: 100%;
  border-spacing: 15px;
  margin-bottom: 20px;
}

.filter-table label {
  font-weight: bold;
}

.filter-table select,
.filter-table input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

/* Responsiv knapp */
.filter-button,
.reset-button {
  padding: 10px 20px;
  background-color: #17a2b8;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 10px;
}

.reset-button {
  background-color: #6c757d;
}

.filter-button:hover,
.reset-button:hover {
  opacity: 0.8;
}

/* Container för responsiv tabell */
.table-container {
  overflow-x: auto;
}

.statistics-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.statistics-table th,
.statistics-table td {
  border: 1px solid #ddd;
  text-align: center;
  padding: 12px;
}

.statistics-table th {
  background-color: #f9f9f9;
}

/* Responsiv anpassning för små skärmar */
@media (max-width: 1024px) {
  .statistics-container {
    width: 100%;
    padding: 10px;
  }

  .filter-table {
    border-spacing: 10px;
  }

  .filter-table td {
    display: block;
    width: 100%;
  }

  .filter-table label {
    display: block;
    margin-bottom: 5px;
  }

  .filter-table select,
  .filter-table input {
    width: 100%;
  }

  .button-container {
    text-align: center;
  }
}

@media (max-width: 768px) {
  .filter-table {
    margin-bottom: 20px;
    border-spacing: 10px;
  }

  .filter-table tr {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .filter-table td {
    flex: 1 1 calc(50% - 20px); /* Flex layout för att passa i två kolumner */
    margin: 5px;
  }

  .filter-table td label {
    font-size: 14px;
  }

  .filter-table select,
  .filter-table input {
    padding: 8px;
    font-size: 14px;
  }

  .statistics-table th,
  .statistics-table td {
    font-size: 12px;
    padding: 10px;
  }
}

@media (max-width: 480px) {
  .filter-table tr {
    flex-direction: column; /* Byt till kolumnlayout på små skärmar */
  }

  .filter-table td {
    flex: 1 1 100%; /* Gör cellerna 100% breda */
    margin: 5px 0;
  }

  .filter-table label {
    font-size: 14px;
  }

  .filter-table select,
  .filter-table input {
    padding: 8px;
    font-size: 14px;
    width: 100%;
  }

  .statistics-table th,
  .statistics-table td {
    padding: 8px;
    font-size: 10px;
  }

  .total-sales {
    margin: 20px 0;
    text-align: center;
    font-weight: bold;
    font-size: 18px;
    color: #17a2b8;
  }
}

/* Modal stil */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
}

.modal-content h3 {
  margin-bottom: 20px;
}

.modal-content input[type="date"] {
  padding: 10px;
  font-size: 16px;
  margin-bottom: 20px;
}

.modal-content button {
  padding: 10px 20px;
  margin: 5px;
  cursor: pointer;
}

.modal-content button:first-child {
  background-color: #17a2b8;
  color: white;
  border: none;
  border-radius: 5px;
}

.modal-content button:last-child {
  background-color: #6c757d;
  color: white;
  border: none;
  border-radius: 5px;
}