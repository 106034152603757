/* Login.css */

.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f5f5f5;
  }
  
  .login-box {
    background-color: #ffffff;
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    width: 100%;
    text-align: center;
  }
  
  .login-box h1 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .login-box label {
    display: block;
    font-weight: bold;
    text-align: left;
    margin-bottom: 8px;
  }
  
  .login-box input {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .login-box button {
    width: 100%;
    padding: 10px;
    background-color: #2c3e50;
    color: #ffffff;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .login-box button:hover {
    background-color: #1a252f;
  }
  
  .forgot-password {
    display: block;
    margin-top: 20px;
    color: #888;
    text-decoration: none;
  }
  
  .forgot-password:hover {
    text-decoration: underline;
  }