/* ForgotPassword.css */
.forgot-password-container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-color: #f9f9f9;
  }
  
  .forgot-password-box {
    background-color: #fff;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: 300px;
    text-align: center;
  }
  
  .forgot-password-box h2 {
    font-size: 1.8em;
    color: #333;
    margin-bottom: 20px;
  }
  
  .forgot-password-box form {
    display: flex;
    flex-direction: column;
  }
  
  .forgot-password-box label {
    margin-bottom: 8px;
    color: #666;
    font-size: 0.9em;
  }
  
  .forgot-password-box input {
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  .forgot-password-box button {
    padding: 12px;
    color: #fff;
    background-color: #3498db;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .forgot-password-box button:hover {
    background-color: #2980b9;
  }
  
  .success-message {
    color: green;
    margin-top: 10px;
  }
  
  .error-message {
    color: red;
    margin-top: 10px;
  }