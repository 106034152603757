/*ControllerStatistics.css */

/* Container-styling */
.statistics-container {
  width: 90%;
  max-width: 1200px;
  margin: 20px auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Rubrik */
.statistics-container h1 {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}

/* Filtersektion */
.filter-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 20px;
  gap: 15px;
}

/* Filteritem-styling */
.filter-item {
  flex: 1;
  min-width: 200px;
}

.filter-item label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.filter-item input,
.filter-item select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 14px;
}

/* Knappar */
.filter-buttons {
  display: flex;
  gap: 10px;
}

.filter-button,
.reset-button {
  padding: 4px 8px; /* Mindre padding för en kompakt storlek */
  font-size: 12px; /* Mindre textstorlek */
  color: #fff;
  border: none;
  border-radius: 15px; /* Rundad design för mindre knapp */
  cursor: pointer;
  transition: all 0.3s ease;
  background: linear-gradient(180deg, #333, #111); /* Glansig mörk gradient */
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
  position: relative;
  overflow: hidden;
  margin-bottom: 5px; /* Mellanrum mellan knapparna i vertikal riktning */
}

.reset-button {
  background-color: #dc3545;
}

.filter-button:hover {
  background-color: #138496;
}

.reset-button:hover {
  background-color: #c82333;
}

/* Totalsumma */
.total-sales {
  text-align: center;
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: bold;
}

/* Tabell-container */
.table-container {
  overflow-x: auto; /* Lägg till horisontell scroll på små skärmar */
}

/* Tabellens styling */
.statistics-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.statistics-table th,
.statistics-table td {
  padding: 12px;
  border: 1px solid #ddd;
  text-align: center;
  font-size: 14px;
}

.statistics-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.statistics-table tbody tr:hover {
  background-color: #f1f1f1;
}

/* Responsiv styling */
@media (max-width: 768px) {
  .statistics-container {
      padding: 10px;
  }

  .filter-section {
      flex-direction: column;
  }

  .filter-item {
      min-width: 100%;
  }

  .filter-buttons {
      justify-content: center;
  }
}

/* Edit modal */
.edit-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.edit-modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 300px;
  text-align: center;
}

.edit-modal-content h3 {
  margin-bottom: 20px;
}

.edit-modal-content label {
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
}

.edit-modal-content input {
  width: 100%;
  padding: 8px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

/* Mindre och glansig stil för "Åtgärd"-knappar */
.status-button,
.edit-button {
  padding: 4px 8px; /* Mindre padding för en kompakt storlek */
  font-size: 12px; /* Mindre textstorlek */
  color: #fff;
  border: none;
  border-radius: 15px; /* Rundad design för mindre knapp */
  cursor: pointer;
  transition: all 0.3s ease;
  background: linear-gradient(180deg, #333, #111); /* Glansig mörk gradient */
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
  position: relative;
  overflow: hidden;
  margin-bottom: 5px; /* Mellanrum mellan knapparna i vertikal riktning */

}

/* Glanseffekt på knapparna */
.status-button::before,
.edit-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50%;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 15px 15px 0 0;
  transition: opacity 0.3s;
}

.status-button:hover,
.edit-button:hover {
  background: linear-gradient(180deg, #444, #222);
  box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.3);
}