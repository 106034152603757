/* Grundläggande stil för företag-komponenten */
.företag-container {
    font-family: 'Arial', sans-serif;
    padding: 20px;
    background-color: #f3f6fb;
    min-height: 100vh;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  /* Rubrikstil */
  .företag-container h1 {
    font-size: 2.2em;
    color: #2c3e50;
    margin-bottom: 20px;
    text-align: center;
  }
  
  /* Stil för meddelanden */
  .message {
    color: #27ae60;
    background-color: #eafaf1;
    padding: 10px 20px;
    border-radius: 5px;
    margin: 15px 0;
    text-align: center;
    font-size: 1.1em;
  }
  
  /* Formulärstil */
  .företag-form {
    width: 100%;
    max-width: 400px;
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 30px;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
  }
  
  .form-group label {
    font-size: 1em;
    color: #2c3e50;
    margin-bottom: 5px;
  }
  
  .form-group input {
    padding: 10px;
    font-size: 1em;
    border-radius: 5px;
    border: 1px solid #dcdde1;
  }
  
  .form-group input:focus {
    outline: none;
    border-color: #3498db;
  }
  
  /* Knappstil */
  .företag-form button {
    width: 100%;
    padding: 12px;
    font-size: 1.1em;
    color: white;
    background-color: #3498db;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .företag-form button:hover {
    background-color: #2980b9;
  }
  
  /* Företagslista */
  .company-list {
    width: 100%;
    max-width: 500px;
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .company-list li {
    background-color: white;
    padding: 15px 20px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 10px;
    font-size: 1.1em;
    color: #34495e;
    display: flex;
    justify-content: space-between;
  }
  
  .company-list li:last-child {
    margin-bottom: 0;
  }
  
  .company-list li:before {
    content: "🏢";
    margin-right: 10px;
  }
  
  /* Mobilrespons */
  @media (max-width: 768px) {
    .företag-container {
      padding: 10px;
    }
  
    .företag-container h1 {
      font-size: 1.8em;
    }
  
    .form-group label, .form-group input, .företag-form button {
      font-size: 1em;
    }
  
    .company-list li {
      font-size: 1em;
    }
  }
  .delete-button {
    margin-left: 10px;
    padding: 5px 10px;
    background-color: #e74c3c;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.9em;
  }
  
  .delete-button:hover {
    background-color: #c0392b;
  }