/* SalesSpecification.css */

.sales-specification-container {
    max-width: 600px;
    margin: 50px auto;
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .sales-specification-container h1,
  .sales-specification-container h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .sales-specification-container form {
    display: flex;
    flex-direction: column;
  }
  
  .sales-specification-container label {
    margin-bottom: 8px;
    font-weight: bold;
  }
  
  .sales-specification-container input[type="text"],
  .sales-specification-container input[type="number"],
  .sales-specification-container select,
  .sales-specification-container textarea {
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .sales-specification-container button {
    padding: 10px;
    background-color: #333;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;
  }
  
  .sales-specification-container button:hover {
    background-color: #555;
  }

  .sales-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .sales-table th,
  .sales-table td {
    border: 1px solid #ddd;
    text-align: left;
    padding: 8px;
  }
  
  .sales-table th {
    background-color: #f2f2f2;
  }