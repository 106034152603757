.sales-info-container {
  max-width: 800px;
  margin: 50px auto;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.sales-info-container h1 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 24px;
  color: #333;
}

.sales-info-container form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.sales-info-container label {
  font-weight: bold;
  margin-bottom: 5px;
  color: #333;
}

.sales-info-container input,
.sales-info-container select {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 10px;
}

.sales-info-container button {
  padding: 12px;
  background-color: #333;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  font-size: 16px;
}

.sales-info-container button:hover {
  background-color: #555;
}

.sales-info-container .user-info button {
  margin-bottom: 20px;
}

.sales-info-container select {
  margin-top: 20px;
  margin-bottom: 20px;
}

/* Nytt CSS för linjär placering av radio-knappar och etiketter */
.sales-info-container .search-type {
  display: flex;
  flex-direction: row; /* Gör radio-knappar linjära */
  gap: 20px; /* Lägger till avstånd mellan dem */
  margin-bottom: 15px;
}

.sales-info-container .search-type label {
  display: flex;
  align-items: center;
  font-weight: normal;
}

.sales-info-container .search-type input[type="radio"] {
  margin-right: 10px;
}

.user-info {
  margin-top: 30px;
  background-color: #fff;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.user-info p {
  margin: 10px 0;
  font-size: 16px;
  color: #333;
}

.specification-details {
  margin-top: 20px;
  padding: 15px;
  background-color: #e9ecef;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.specification-details h2 {
  margin-bottom: 15px;
  font-size: 20px;
  color: #333;
  text-align: center;
}

.specification-details p {
  margin: 8px 0;
  font-size: 16px;
  color: #333;
}

.specification-details button {
  margin-top: 20px;
  padding: 10px;
  background-color: #c0392b;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  font-size: 16px;
}

.specification-details button:hover {
  background-color: #e74c3c;
}

.user-profile {
  margin-top: 30px;
  background-color: #fff;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.user-profile h2 {
  text-align: center;
  font-size: 22px;
  margin-bottom: 15px;
  color: #333;
}

.user-profile p {
  margin: 8px 0;
  font-size: 16px;
  color: #333;
}