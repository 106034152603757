/* Grundläggande stil för hela dashboard */
.sales-manager-dashboard {
    font-family: 'Arial', sans-serif;
    padding: 20px;
    background-color: #f5f5f5;
    min-height: 100vh; /* Gör att sidan täcker hela höjden */
    box-sizing: border-box;
  }
  
  /* Rubrikstil */
  .dashboard-title {
    font-size: 2em;
    margin-bottom: 20px;
    color: #333;
    text-align: center;
  }
  
  /* Stil för knappen för att uppdatera data */
  .refresh-btn {
    display: block;
    margin: 0 auto 20px;
    padding: 10px 20px;
    font-size: 1em;
    color: white;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .refresh-btn:hover {
    background-color: #0056b3;
  }
  
  /* Stil för teamstatistiksektionen */
  .team-sales-stats, .my-sales-stats {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
  }
  
  .team-sales-stats h2, .my-sales-stats h2 {
    font-size: 1.6em;
    margin-bottom: 10px;
    color: #333;
  }
  
  .team-sales-stats p, .my-sales-stats p {
    font-size: 1.2em;
    color: #555;
  }
  
  /* Stil för toppanvändare och toppteam listor */
  .top-users, .top-sales-managers {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
  }
  
  .top-users h2, .top-sales-managers h2 {
    font-size: 1.6em;
    margin-bottom: 10px;
    color: #333;
  }
  
  .top-users ul, .top-sales-managers ul {
    list-style-type: none;
    padding: 0;
  }
  
  .top-users li, .top-sales-managers li {
    font-size: 1.2em;
    color: #333;
    padding: 10px 0;
    border-bottom: 1px solid #ddd;
  }
  
  .top-users li:last-child, .top-sales-managers li:last-child {
    border-bottom: none;
  }
  
  /* Gör dashboard responsiv för mobil */
  @media (max-width: 768px) {
    .sales-manager-dashboard {
      padding: 10px;
    }
  
    .refresh-btn {
      width: 100%;
    }
  
    .team-sales-stats, .my-sales-stats, .top-users, .top-sales-managers {
      padding: 15px;
    }
  
    .dashboard-title {
      font-size: 1.5em;
    }
    
    .team-sales-stats h2, .my-sales-stats h2, .top-users h2, .top-sales-managers h2 {
      font-size: 1.4em;
    }
  
    .team-sales-stats p, .my-sales-stats p, .top-users li, .top-sales-managers li {
      font-size: 1em;
    }
  }