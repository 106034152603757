/* Grundläggande stil för hela dashboard */
.dashboard-content {
  font-family: 'Arial', sans-serif;
  padding: 20px;
  background-color: #f3f6fb;
  min-height: 100vh;
  box-sizing: border-box;
}

/* Rubrikstil */
.dashboard-title {
  font-size: 2.2em;
  margin-bottom: 20px;
  color: #2c3e50;
  text-align: center;
}

/* Stil för knappen för att uppdatera data */
.refresh-btn {
  display: block;
  margin: 0 auto 20px;
  padding: 10px 20px;
  font-size: 1em;
  color: white;
  background-color: #3498db;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.refresh-btn:hover {
  background-color: #2980b9;
  transform: scale(1.05);
}

/* Modern grid layout för statistikkort */
.stats-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

/* Stil för varje statistikkort */
.stats-card {
  background-color: white;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  transition: box-shadow 0.3s ease, transform 0.2s ease;
}

.stats-card:hover {
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2);
  transform: translateY(-5px);
}

.stats-card h2 {
  font-size: 1.4em;
  color: #2c3e50;
  margin: 10px 0;
}

.stats-card .stat-number {
  font-size: 2.4em;
  color: #3498db;
  font-weight: bold;
  margin: 5px 0;
}

.stats-card p {
  font-size: 1.1em;
  color: #34495e;
}

/* Kompakt layout för företagsstatistik i en grid */
.company-stats-box {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 15px;
  margin-top: 20px;
}

/* Kompakt box-stil för varje företag */
.company-stats-box-card {
  background-color: white;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.1);
  text-align: center;
  font-size: 0.9em;
  transition: box-shadow 0.3s ease, transform 0.2s ease;
}

.company-stats-box-card:hover {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
  transform: translateY(-3px);
}

.company-stats-box-card h3 {
  font-size: 1.2em;
  color: #2c3e50;
  margin-bottom: 8px;
}

.company-stats-box-card .stat {
  font-size: 0.95em;
  color: #34495e;
  margin: 4px 0;
}

/* Mobil respons */
@media (max-width: 768px) {
  .dashboard-content {
    padding: 10px;
  }

  .dashboard-title {
    font-size: 1.8em;
  }

  .stats-card h2, .company-stats-box-card h3 {
    font-size: 1.3em;
  }

  .stats-card .stat-number, .company-stats-box-card .stat {
    font-size: 1em;
  }
}