.sales-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .sales-form .form-group {
    margin-bottom: 15px;
  }
  
  .sales-form label {
    display: block;
    margin-bottom: 5px;
  }
  
  .sales-form input {
    width: 100%;
    padding: 8px;
    font-size: 16px;
  }
  
  .sales-list {
    margin-top: 20px;
    list-style-type: none;
    padding: 0;
  }
  
  .sales-item {
    border-bottom: 1px solid #ddd;
    padding: 10px 0;
  }
  
  .sales-item p {
    margin: 5px 0;
  }
  
  .message {
    color: green;
    margin-top: 10px;
  }